import React, { useState, useEffect } from "react";
import "./CaruselCard.css";
import { IoLocationSharp } from "react-icons/io5";
import useIsMobile from "../../../custom-hooks/useIsMobile";
const CaruselCard = ({
  title,
  location,
  image,
  setDisplayPopup,
  setCurrentIndex,
  index,
  width,
}) => {
  const transformations = "q_auto,f_auto,fl_lossy,c_scale,w_auto,dpr_auto"; // Ajusta l'amplada automàticament al contenidor i resolució de pantalla
  const transformedImageUrl = image.replace(
    "/upload/",
    `/upload/${transformations}/`
  );
  const [calculatedHeight, setCalculatedHeight] = useState("0px");
  const isMobile = useIsMobile();
  useEffect(() => {
    // Assegura't que width és un número
    const numericWidth = parseFloat(width);
    if (!isNaN(numericWidth)) {
      // Calcular l'alçada en píxels basada en el width en píxels
      const height = `calc(${numericWidth * 0.22 * 1.4}px)`;
      setCalculatedHeight(height);
    }
  }, [width]);
  return (
    <div
      className="carusel-card__container"
      style={!isMobile ? { height: calculatedHeight } : { height: "100%" }}
      onClick={() => {
        setCurrentIndex(index);
        setDisplayPopup(true);
      }}
    >
      {image !== undefined && (
        <img
          src={transformedImageUrl}
          alt=""
          className="carusel-card__image"
          onerror={image}
        />
      )}
      <div className="carusel-card__text-wrapper">
        <span className="carusel-card__title">{title}</span>
        <div className="carusel-card__ubi-wrapper">
          <IoLocationSharp color="white" size={!isMobile ? 24 : 18} />
          <span className="carusel-card__location">{location}</span>
        </div>
      </div>
    </div>
  );
};

export default CaruselCard;
